import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Navigate } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { useAuthConfig, useSession } from './AuthProvider'

const SignInCompletion = props => {
  const [cookies] = useCookies()
  const { afterAuthLocationCookieName } = useAuthConfig()
  const { setIsAuthenticated } = useSession()
  const destination = cookies[afterAuthLocationCookieName] || '/'

  const [amplifyHasUpdatedState, setAmplifyHasUpdatedState] = useState(false)

  const checkAmplifyState = async () => {
    try {
      await Auth.currentAuthenticatedUser()
      setIsAuthenticated(true)
      setAmplifyHasUpdatedState(true)
    } catch (inner) {
      setIsAuthenticated(false)
      const error = new Error('Error while finishing sign in process.')
      error.inner = inner
      console.error(error)
    }
  }

  useEffect(() => {
    checkAmplifyState()
  }, [])

  return (
    <div>
      {props.children}
      {destination && amplifyHasUpdatedState && (
        <Navigate to={destination} replace />
      )}
    </div>
  )
}

export default SignInCompletion
