const noop = () => {}

export const isFunction = obj => {
  return !!(obj && obj.constructor && obj.call && obj.apply)
}

export const addAdhocCallback = (listeners, newCallback) => {
  if (Array.isArray(listeners)) {
    return [...listeners, newCallback]
  } else if (isFunction(listeners)) {
    return [listeners, newCallback]
  } else if (!listeners) {
    return newCallback
  }
}

export const removeAdhocCallback = (listeners, callbackToRemove) => {
  if (Array.isArray(listeners)) {
    return listeners.filter(i => i !== callbackToRemove)
  } else if (isFunction(listeners) && listeners === callbackToRemove) {
    return noop
  } else {
    return listeners
  }
}

// Sync sleep function
export const sleep = ms => {
  var start = new Date().getTime()
  var expire = start + ms
  while (new Date().getTime() < expire) {}
}
